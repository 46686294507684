<template>
    <form @submit.prevent="updateCompany" class="invite-form">
        <h3>Edit My Company</h3>

        <div class="card">
            <div class="card-body">

                <div class="form-group">
                    <label for="first_name">{{ $t('First name') }}</label>
                    <input v-model="company.name" type="text" id="first_name" required>
                    <div v-if="errors[`name`]" v-for="error in errors[`name`]" :key="error"
                         class="text-danger">{{
                            error
                        }}
                    </div>
                </div>

                <router-link :to="{ name: 'ViewMyCompany' }" class="card-link">Cancel link</router-link>
                <a @click.prevent="updateCompany" href="#" class="card-link">Update link</a>
            </div>

        </div>
    </form>
</template>

<script>
import axios from "@/axios";

export default {
    name: "EditMyCompany",
    data() {
        return {
            company: {},


        }
    },
    created() {
        this.getCompany()
    },
    methods: {
        getCompany() {
            axios.get('/client/company/')
                .then(response => {
                    this.company = response.data.data
                })
        },
        updateCompany() {
            axios.put('/client/company', this.company)
                .then(response => {
                    alert('saved!')
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        }
    }
}
</script>

<style scoped>

</style>